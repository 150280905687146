<template>
  <b-card class="card-custom card-stretch gutter-b" no-body>
    <b-card-header>
      <b-card-title class="font-weight-bolder">
        Indicative
      </b-card-title>

      <div class="card-toolbar">
        <div class="dropdown dropdown-inline">
          <div role="group" class="input-group input-group-sm">
            <b-input-group size="sm">
              <b-input-group-prepend v-bind:is-text="true">
                <i class="fas fa-search" />
              </b-input-group-prepend>

              <b-form-input class="form-control" size="sm" placeholder="indicativ, nr. auto, POS" v-model="search" />
            </b-input-group>
          </div>
        </div>
      </div>
    </b-card-header>

    <b-card-body>
      <Table
        v-bind:loading="loading"
        v-bind:cars="cars"
        v-bind:sort.sync="sort"
        v-on:grant-priority="grantPriority"
        v-on:revoke-priority="revokePriority"
        v-on:suspend="suspend"
        v-on:reinstate="reinstate"
      />
    </b-card-body>

    <b-card-footer>
      <Pagination
        v-model="page"
        v-bind:page-size="pageSize"
        v-bind:page-options="pageOptions"
        v-bind:total="total"
        v-on:page-size-change="pageSizeChanged"
      />
    </b-card-footer>

    <GrantPriorityDialog ref="grantPriorityDialog" />
    <RevokePriorityDialog ref="revokePriorityDialog" />

    <SuspendDialog ref="suspendDialog" />
    <ReinstateDialog ref="reinstateDialog" />
  </b-card>
</template>

<script>
import lodash from "lodash";
import moment from "moment";
import { mapActions } from "vuex";

import api from "@/core/services/api";
import notify from "@/core/services/notifications";
import { formatPhoneNumber, formatAddress } from "@/core/utils";

import Pagination from "@/view/content/widgets/Pagination.vue";
import GrantPriorityDialog  from "@/view/content/dialogs/cars/GrantPriority.vue";
import RevokePriorityDialog from "@/view/content/dialogs/cars/RevokePriority.vue";
import SuspendDialog   from "@/view/content/dialogs/cars/Suspend.vue";
import ReinstateDialog from "@/view/content/dialogs/cars/Reinstate.vue";
import ListMixin from "@/view/mixins/List.js";

import Table from "./cars/list/Table";

function mapCar(car) {
  const created  = moment(car.created);
  const enabled  = car.enabled  ? moment(car.enabled)  : null;
  const lastSeen = car.lastSeen ? moment(car.lastSeen) : null;

  let status = car.status;
  if (!car.enabled) {
    status = "disabled";
  } else if (car.driver && car.driver.suspended) {
    status = "suspended";
  }

  return {
    id: car.id,

    status,

    created: Object.freeze(created),
    enabled: Object.freeze(enabled),
    lastSeen: Object.freeze(lastSeen),

    drivers: car.drivers.map(mapCarDriver),
    driver:  car.driver ? mapCarDriver(car.driver) : null,
    order:   car.order,
    station: car.station,
  };
}

function mapCarDriver(driver) {
  const priority = driver.priority.range || driver.priority.time.length > 0 ? driver.priority : null;

  return {
    id:   driver.id,
    name: driver.name,

    suspended: driver.suspended,
    priority,
  };
}

export default {
  name: "Cars",

  components: {
    Table,
    Pagination,

    GrantPriorityDialog,
    RevokePriorityDialog,

    SuspendDialog,
    ReinstateDialog,
  },

  filters: {
    address: formatAddress,
    phone:   formatPhoneNumber,
  },

  mixins: [
    ListMixin
  ],

  data() {
    return {
      cars: [],

      debounceDelay: moment.duration(250, "ms"),
      defaultSort: "id",
    };
  },

  methods: {
    ...mapActions(["overridePageLayoutConfig"]),

    async load() {
      this.loading = true;

      const response = await api.cars.list(this.offset, this.limit, this.search, this.sort);

      this.loading = false;
      this.total   = response.range.total;
      this.cars    = response.results.map(mapCar);
    },

    async refresh(car) {
      const response = await api.cars.load(car.id);
      const mapped   = mapCar(response);

      const index = this.cars.findIndex(c => c.id === car.id);
      if (index !== -1) {
        this.$set(this.cars, index, mapped);
      }
    },

    async grantPriority(car) {
      const excludeRange = car.driver && car.driver.priority && car.driver.priority.range;

      const granted = await this.$refs.grantPriorityDialog.show(car, excludeRange);

      if (granted) {
        const duration = granted.duration.format("HH:mm:ss");

        if (granted.radius) {
          await api.cars.grantRangePriority(car.id, granted.radius, duration);
        } else {
          await api.cars.grantTimePriority(car.id, duration);
        }

        await this.refresh(car);
      }
    },

    async revokePriority({ car, priority }) {
      const revoke = await this.$refs.revokePriorityDialog.show(car, priority);

      if (revoke) {
        if (lodash.isString(revoke)) {
          const priorityId = revoke;

          if (priority.radius) {
            await api.cars.revokeRangePriority(car.id, priorityId);
          } else {
            await api.cars.revokeTimePriority(car.id, priorityId);
          }
        } else {
          await api.cars.revokeAllPriorities(car.id);
        }

        await this.refresh(car);
      }
    },

    async suspend(car) {
      const suspended = await this.$refs.suspendDialog.show(car);

      if (suspended) {
        const { drivers, duration, reason } = suspended;

        for (const driver of drivers) {
          await api.drivers.suspend(driver.id, duration.toISOString(), reason);

          driver.suspended = {
            at:    moment(),
            until: moment().add(duration),
            reason,
          };

          notify.info(`Șoferul <b>${driver.name}</b> a fost blocat pentru <b>${duration.humanize()}</b>.`);
        }
      }
    },

    async reinstate(car) {
      const reinstated = await this.$refs.reinstateDialog.show(car);

      if (reinstated) {
        for (const driver of reinstated) {
          await api.drivers.reinstate(driver.id);

          driver.suspended = null;

          notify.info(`Șoferul <b>${driver.name}</b> a fost deblocat.`);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  .card-header {
    border-bottom: none;
  }

  .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .card-footer {
    .pagination {
      margin-bottom: 0;
    }

    .dropdown {
      margin-bottom: 0;
    }
  }
}
</style>

<template>
  <b-modal
    centered
    ok-title="Deblochează"
    cancel-title="Renunță"
    cancel-variant="secondary"
    v-model="visible"
    v-bind:title="title"
    v-bind:ok-disabled="selected.length === 0"
    v-on:hide="resolve($event.trigger === 'ok' ? selected : false)"
  >
    <b-form-group
      label="Alegeți șoferii pe care doriți să îi deblocați:"
      label-class="h5 text-dark"
    >
      <b-form-checkbox-group
        switches
        size="lg"
        v-if="car"
        v-model="selected"
        v-bind:options="drivers"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
function mapDriverToOption(driver) {
  return {
    text:  driver.name,
    value: driver,

    disabled: !driver.suspended,
  }
}

export default {
  data() {
    return {
      car: null,

      selected: [],

      resolve: null,
      reject:  null,

      visible: false,
    };
  },

  computed: {
    drivers() {
      if (this.car) {
        return this.car.drivers.map(mapDriverToOption);
      } else {
        return [];
      }
    },

    title() {
      if (this.car) {
        return `Deblochează indicativ #${this.car.id}`;
      } else {
          return "";
      }
    },
  },

  destroyed() {
    if (this.reject) {
      this.reject(new Error("Dialog destroyed before resolve."));
    }
  },

  methods: {
    show(car) {
      this.car = car;

      this.selected = [];

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },

    hide() {
      this.car = null;

      this.selected = [];

      this.resolve = null;
      this.reject  = null;

      this.visible = false;
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal {
    .modal-dialog {
      .modal-body {
        .form-group {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .modal-footer {
        justify-content: space-between;
      }
    }
  }
}
</style>

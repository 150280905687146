<template>
  <b-table
    class="table-head-custom"
    primary-key="id"
    stacked="xl"
    show-empty
    small
    fixed
    no-sort-reset
    no-local-sorting
    v-bind:busy="loading"
    v-bind:fields="fields"
    v-bind:items="cars"
    v-bind:sort-by.sync="sortBy"
    v-bind:sort-desc.sync="sortDesc"
    v-on:sort-changed="sortChanged"
  >
    <template v-slot:head()="row">
      <template v-if="sortBy === row.column">
        {{ row.label }}

        <i v-if="sortDesc" class="fas fa-sort-amount-down" />
        <i v-else class="fas fa-sort-amount-up" />
      </template>

      <template v-else>
        {{ row.label }}
      </template>
    </template>

    <template v-slot:cell(id)="row">
      <div class="symbol symbol-50 symbol-light mt-1" v-b-tooltip.hover="statusLabels[row.item.status]">
        <span class="symbol-label">
          <i v-bind:class="[ statusIcons[row.item.status], `text-${statusVariants[row.item.status]}` ]" />
        </span>
      </div>

      <div>
        <a
          href="#"
          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
        >
          {{ row.item.id }}
        </a>

        <div class="text-muted font-weight-bold text-muted d-block">
          <span v-if="row.item.driver" class="text-primary">{{ row.item.driver.name }}</span>
          <span v-if="row.item.driver && row.item.station"> | </span>
          <span v-if="row.item.station" class="text-info">{{ row.item.station.name }} #{{ row.item.station.index }}</span>
        </div>
      </div>
    </template>

    <template v-slot:cell(activity)="row">
      <CarActivity
        v-if="row.item.lastSeen"
        v-bind:last-seen="row.item.lastSeen"
      />

      <CarSuspension
        v-if="row.item.drivers.some(d => d.suspended)"
        v-bind:drivers="row.item.drivers"
      />
    </template>

    <template v-slot:cell(priority)="row">
      <CarPriority
        v-if="row.item.driver && row.item.driver.priority"
        v-bind:range="row.item.driver.priority.range"
        v-bind:time="row.item.driver.priority.time"
        v-on:revoke="revokePriority(row.item, $event)"
      />
    </template>

    <template v-slot:cell(order)="row">
      <CarOrder
        v-if="row.item.order"
        v-bind:order="row.item.order"
      />
    </template>

    <template v-slot:cell(actions)="row">
      <CarActions
        v-bind:car="row.item"
        v-on="$listeners"
      />
    </template>
  </b-table>
</template>

<script>
import moment from "moment";

import TableMixin from "@/view/mixins/Table.js";

import CarActions    from "./CarActions.vue";
import CarActivity   from "./CarActivity.vue";
import CarOrder      from "./CarOrder.vue";
import CarPriority   from "./CarPriority.vue";
import CarSuspension from "./CarSuspension.vue";

export default {
  components: {
    CarActions,
    CarActivity,
    CarOrder,
    CarPriority,
    CarSuspension,
  },

  mixins: [
    TableMixin
  ],

  props: {
    cars: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fields: [
        { key: "id",        label: "Indicativ",  class: "id", sortable: true, sortDirection: "asc" },
        { key: "activity",  label: "Activitate", class: "activity" },
        { key: "priority",  label: "Prioritate", class: "status" },
        { key: "order",     label: "Comandă",    class: "order" },
        { key: "actions",   label: "Acțiuni",    class: "actions" }
      ],

      statusIcons: Object.freeze({
        disabled:  "fas fa-lock",
        suspended: "fas fa-minus-circle",

        away: "fas fa-taxi",
        free: "fas fa-taxi",
        busy: "fas fa-phone-slash",

        inOrder:   "fas fa-user-clock",
        inStation: "fas fa-taxi",
      }),

      statusLabels: Object.freeze({
        disabled:  "blocat",
        suspended: "suspendat",

        away: "inactiv",
        free: "liber",
        busy: "ocupat",

        inOrder:   "are comandă",
        inStation: "în stație",
      }),

      statusVariants: Object.freeze({
        disabled:  "muted",
        suspended: "danger",

        away: "muted",
        free: "success",
        busy: "danger",

        inOrder:   "primary",
        inStation: "info",
      }),
    };
  },

  methods: {
    revokePriority(car, priority) {
      this.$emit("revoke-priority", {
        car,
        priority,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table::v-deep {
  > thead, tfoot {
    > tr {
      > th {
        padding: 0.75rem 1rem;
        background: none !important;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &.id {
          width: 20rem;
        }

        &.activity {
          width: 35rem;
        }

        &.priority {
          width: 35rem;
        }

        &.order {
          width: 35rem;
        }

        &.actions {
          width: 13rem;
        }

        &[aria-sort = ascending], &[aria-sort = descending] {
          color: $primary !important;

          i {
            color: $primary;
            font-size: 0.9rem;
            vertical-align: middle;
          }
        }
      }
    }
  }

  > tbody {
    > tr {
      > td {
        padding: 1rem;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &.id > div {
          display: flex;
          align-items: center;
        }

        .symbol {
          margin-right: 1.5rem;

          .symbol-label {
            i {
              font-size: 24pt;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <b-modal
    centered
    ok-title="Blochează"
    ok-variant="danger"
    cancel-title="Renunță"
    cancel-variant="secondary"
    v-model="visible"
    v-bind:title="title"
    v-bind:ok-disabled="!valid"
    v-on:hide="hidden"
  >
    <b-form-group
      label="Șoferi blocați:"
      label-class="h5 text-dark"
    >
      <b-form-checkbox-group
        switches
        size="lg"
        v-if="car"
        v-model="selectedDrivers"
        v-bind:options="driverOptions"
      />
    </b-form-group>

    <b-form-group
      label="Durată blocare:"
      label-class="h5 text-dark"
    >
      <b-form-radio-group
        buttons
        button-variant="outline-danger"
        v-model="selectedDuration"
        v-bind:options="durationOptions"
      />
    </b-form-group>

    <b-form-group
      label="Motiv blocare:"
      label-class="h6"
    >
      <b-form-input v-model="reason" placeholder="introduceți un motiv" />
    </b-form-group>
  </b-modal>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      durations: [
        moment.duration(1,  "hour"),
        moment.duration(2,  "hours"),
        moment.duration(3,  "hours"),
        moment.duration(4,  "hours"),
        moment.duration(8,  "hours"),
        moment.duration(12, "hours"),
        moment.duration(1,  "day"),
        moment.duration(1,  "week")
      ],

      car: null,

      selectedDuration: null,
      selectedDrivers:  [],
      reason: null,

      resolve: null,
      reject:  null,

      visible: false,
    };
  },

  computed: {
    drivers() {
      if (this.car) {
        return this.car.drivers;
      } else {
        return [];
      }
    },

    driverOptions() {
      return this.drivers.map(driver => ({
        text:  driver.name,
        value: driver,

        disabled: !!driver.suspended,
      }));
    },

    durationOptions() {
      return this.durations.map(duration => ({
        text:  duration.humanize(),
        value: duration,
      }));
    },

    title() {
      if (this.car) {
        return `Blochează indicativ #${this.car.id}`;
      } else {
        return "";
      }
    },

    valid() {
      return this.selectedDrivers.length > 0 &&
             this.selectedDuration &&
             this.reason &&
             this.reason.length > 0;
    },
  },

  destroyed() {
    if (this.reject) {
      this.reject(new Error("Dialog destroyed before resolve."));
    }
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    show(car) {
      this.car = car;

      this.selectedDrivers  = [];
      this.selectedDuration = null;

      this.reason = null;

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },

    hide() {
      this.car = null;

      this.selectedDrivers  = [];
      this.selectedDuration = null;

      this.reason = null;

      this.resolve = null;
      this.reject  = null;

      this.visible = false;
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    hidden(event) {
      let result = false;

      if (event.trigger === "ok") {
        result = {
          drivers:  this.selectedDrivers,
          duration: this.selectedDuration,
          reason:   this.reason,
        };
      }

      this.resolve(result);
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal {
    .modal-dialog {
      .modal-body {
        .form-group {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .modal-footer {
        justify-content: space-between;
      }
    }
  }
}
</style>

import AwesomeDebouncePromise from "awesome-debounce-promise";

export default {
  data() {
    return {
      page: 1,
      pageSize: 5,
      pageOptions: [ 5, 10, 25, 50 ],
      total: 0,

      search: undefined,
      sort: undefined,

      loading: false,
    }
  },

  computed: {
    offset() {
      return (this.page - 1) * this.pageSize;
    },

    limit() {
      return this.pageSize;
    },
  },

  watch: {
    async page() {
      await this.load();
    },

    async pageSize() {
      await this.load();
    },

    async search(search) {
      if (search) {
        this.sort = undefined;
      } else {
        this.sort = this.defaultSort;
      }

      await this.onSearch(search);
    },

    async sort() {
      await this.load();
    },
  },

  async created() {
    this.sort = this.defaultSort;

    await this.load();

    this.onSearch = AwesomeDebouncePromise(async search => {
      this.page   = 1;
      this.search = search;

      await this.load();
    }, this.debounceDelay.asMilliseconds());
  },

  methods: {
    pageSizeChanged(pageSize) {
      this.page     = 1;
      this.pageSize = pageSize;
    },
  },
}

<template>
  <Progress
    v-bind:color="progressColor"
    v-bind:value="progressValue"
    v-bind:max="progressMax"
  >
    <template v-slot:left>
      <div class="text-muted mr-2 font-size-sm font-weight-bold">
        <span class="text-primary">Licitat: {{ duration | duration }}</span>
        |
        <span class="text-dark">Trecut: {{ elapsed | duration }}</span>
        |
        <span class="text-danger" v-if="late.asSeconds() > 0">Întărziat: {{ late | duration }}</span>
        <template v-else>
          <span class="text-success" v-if="remaining.asSeconds() > 60">Rămas: {{ remaining | duration }}</span>
          <span class="text-warning" v-else>Rămas: {{ remaining | duration }}</span>
        </template>
      </div>
    </template>
  </Progress>
</template>

<script>
import moment from "moment";

import Progress from "@/view/content/widgets/Progress.vue";

export default {
  components: { Progress },

  filters: {
    duration: duration  => moment.duration(duration).humanize(),
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    const created   = moment(this.order.created);
    const confirmed = moment(this.order.confirmed);
    const arrived   = this.order.arrived ? moment(this.order.arrived) : null;

    const duration = moment.duration(this.order.duration);
    const expected = confirmed.clone().add(duration);
    const elapsed  = moment.duration(moment().diff(confirmed));

    let remaining = moment.duration(expected.diff(moment()));
    if (remaining.asMilliseconds() < 0)
      remaining = moment.duration(0);

    let late = elapsed.clone().subtract(duration);
    if (late.asMilliseconds() < 0)
      late = moment.duration(0);

    return {
      created:   Object.freeze(created),
      confirmed: Object.freeze(confirmed),
      arrived:   Object.freeze(arrived),

      duration:  Object.freeze(duration),
      expected:  Object.freeze(expected),
      elapsed:   Object.freeze(elapsed),
      remaining: Object.freeze(remaining),
      late:      Object.freeze(late),

      progressValue: elapsed.asSeconds(),
      progressMax:   duration.asSeconds(),
    };
  },

  computed: {
    progressColor() {
      if (this.remaining.asMinutes() <= 0) {
        return "danger";
      } else if (this.remaining.asMinutes() < 1) {
        return "warning";
      } else {
        return "primary";
      }
    },
  },
};
</script>

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    sort: {
      type: String,
      default: null,
      validator: sort => /^[-+]?\w+$/.test(sort),
    },
  },

  data() {
    return {
      sortBy:   null,
      sortDesc: null,
    }
  },

  watch: {
    sort: {
      immediate: true,
      handler(sort) {
        if (sort) {
          this.sortBy   = "-+".includes(sort[0]) ? sort.substring(1) : sort;
          this.sortDesc = sort[0] === "-";
        } else {
          this.sortBy   = null;
          this.sortDesc = false;
        }
      },
    },
  },

  methods: {
    sortChanged(context) {
      const sortDir = context.sortDesc ? "-" : "";

      this.$emit("update:sort", sortDir + context.sortBy);
    },
  },
}

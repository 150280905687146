<template>
  <b-row align-h="between">
    <b-col cols="auto">
      <b-pagination
        first-number
        hide-goto-end-buttons
        size="lg"
        limit="7"
        prev-text="🠔"
        next-text="🠖"
        v-bind:value="page"
        v-bind:total-rows="total"
        v-bind:per-page="pageSize"
        v-on:change="pageChanged"
      />
    </b-col>

    <b-col cols="auto">
      <b-dropdown right v-bind:text="pageSize.toString()">
        <b-dropdown-item-button
          v-for="option in pageOptions"
          v-bind:key="option"
          v-bind:active="option === pageSize"
          v-on:click="pageSizeChanged(option)"
        >
          {{ option }}
        </b-dropdown-item-button>
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import lodash from "lodash";

export default {
  model: {
    prop: "page",
    event: "page-change",
  },

  props: {
    page: {
      type: Number,
      default: 1,
    },

    pageSize: {
      type: Number,
      default: 5,
    },

    pageOptions: {
      type: Array,
      required: true,
      validator: sizes => sizes.every(lodash.isInteger),
    },

    total: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    pageChanged(page) {
      this.$emit("page-change", page);
    },

    pageSizeChanged(pageSize) {
      this.$emit("page-size-change", pageSize);
    },
  },
};
</script>

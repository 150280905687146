<template>
  <Progress
    v-bind:color="progressColor"
    v-bind:variant="progressColor"
    v-bind:value="progressValue"
    v-bind:max="progressMax"
  >
    <template v-slot:left>
      <div class="text-muted mr-2 font-size-sm font-weight-bold">
        <span class="text-primary">Prioritate în zonă de {{ priority.radius }} km</span>
        |
        <span class="text-danger" v-if="expired">Expirată</span>
        <span class="text-warning" v-else-if="almostExpired">Expiră: {{ remaining | future }}</span>
        <span class="text-success" v-else>Expiră: {{ remaining | future }}</span>
      </div>
    </template>

    <template v-slot:right>
      <b-link class="text-muted text-hover-danger" v-on:click="revoke">
        anulează
      </b-link>
    </template>
  </Progress>
</template>

<script>
import moment from "moment";

import Progress from "@/view/content/widgets/Progress.vue";

export default {
  components: {
    Progress,
  },

  filters: {
    future: duration => moment.duration(duration).humanize(true),
  },

  props: {
    priority: {
      type: Object,
      default: null,
    },
  },

  data() {
    const granted  = moment(this.priority.granted);
    const expires  = moment(this.priority.expires);
    const duration = moment.duration(expires.diff(granted));
    const elapsed  = moment.duration(moment().diff(granted));

    let remaining = moment.duration(expires.diff(moment()));
    if (remaining.asMilliseconds() < 0)
      remaining = moment.duration(0);

    return {
      granted:   Object.freeze(granted),
      expires:   Object.freeze(expires),
      duration:  Object.freeze(duration),
      elapsed:   Object.freeze(elapsed),
      remaining: Object.freeze(remaining),

      position: this.priority.position,
      radius:   this.priority.radius,

      progressValue: elapsed.asSeconds(),
      progressMax:   duration.asSeconds(),
    };
  },

  computed: {
    expired() {
      return this.remaining.asMinutes() <= 0;
    },

    almostExpired() {
      return this.remaining.asMinutes() < 10;
    },

    progressColor() {
      if (this.expired) {
        return "danger";
      } else if (this.almostExpired) {
        return "warning";
      } else {
        return "primary";
      }
    },
  },

  methods: {
    revoke() {
      this.$emit("revoke", this.priority);
    },
  },
};
</script>

<template>
  <b-modal
    centered
    ok-title="Anuleză prioritatea"
    cancel-title="Renunță"
    cancel-variant="danger"
    v-model="visible"
    v-bind:title="title"
    v-bind:ok-disabled="hasMultipleTimePriorities && !selected"
    v-on:hide="modalHidden"
  >
    <p v-if="car && hasRangePriority" class="font-size-h6">
      Sunteți sigur că doriți să anulați prioritatea
      <span class="text-primary">în zonă de {{ priority.radius }} km</span>
      pentru indicativul <strong>{{ car.id }}</strong> cu șofer <strong>{{ car.driver.name }}</strong>?
    </p>

    <p v-else-if="car && hasSingleTimePriority" class="font-size-h6">
      Sunteți sigur că doriți să anulați prioritatea
      <span class="text-info">de {{ priorityDuration }}</span>
      pentru indicativul <strong>{{ car.id }}</strong> cu șofer <strong>{{ car.driver.name }}</strong>?
    </p>

    <div v-else-if="car && hasMultipleTimePriorities">
      <p class="font-size-h6">
        Alegeți prioritatea pe care doriți să o anulați
        pentru indicativul <strong>{{ car.id }}</strong> cu șofer <strong>{{ car.driver.name }}</strong>.
      </p>

      <div class="d-flex justify-content-center">
        <b-form-group>
          <b-form-radio-group
            buttons
            button-variant="outline-info"
            v-model="selected"
            v-bind:options="priorityOptions"
          />
        </b-form-group>
      </div>
    </div>

    <p v-else-if="car" class="font-size-h6">
      Sunteți sigur că doriți să anulați <strong class="text-danger">toate prioritățile</strong>
      pentru indicativul <strong>{{ car.id }}</strong> cu șofer <strong>{{ car.driver.name }}</strong>?
    </p>
  </b-modal>
</template>

<script>
import moment from "moment";

function mapTimeOption(priority) {
  const granted  = moment(priority.granted);
  const expires  = moment(priority.expires);
  const duration = moment.duration(expires.diff(granted));

  return {
    text:  duration.humanize(),
    value: priority.id,
  }
}

export default {
  data() {
    return {
      car: null,
      priority: null,

      selected: null,

      resolve: null,
      reject:  null,

      visible: false,
    };
  },

  computed: {
    title() {
      if (this.car) {
          return `Anulează prioritate - ind. ${this.car.id} șofer ${this.car.driver.name}`;
      } else {
          return "";
      }
    },

    hasRangePriority() {
      return this.priority && this.priority.radius;
    },

    hasSingleTimePriority() {
      return this.priority && Array.isArray(this.priority) && this.priority.length === 1;
    },

    hasMultipleTimePriorities() {
      return this.priority && Array.isArray(this.priority) && this.priority.length > 1;
    },

    priorityDuration() {
      if (this.hasSingleTimePriority) {
        const priority = this.priority[0];
        const option   = mapTimeOption(priority);

        return option.text;
      } else {
        return "";
      }
    },

    priorityOptions() {
      if (this.hasMultipleTimePriorities) {
        return this.priority.map(mapTimeOption);
      } else {
        return [];
      }
    },
  },

  destroyed() {
    if (this.reject) {
      this.reject(new Error("Dialog destroyed before resolve."));
    }
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    show(car, priority) {
      this.car      = car;
      this.priority = priority;

      this.selected = null;

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },

    hide() {
      this.car      = null;
      this.priority = null;

      this.selected = null;

      this.resolve = null;
      this.reject  = null;

      this.visible = false;
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    modalHidden(event) {
      let result = false;

      if (event.trigger === 'ok') {
        if (this.hasRangePriority) {
          result = this.priority.id;
        } else if (this.hasSingleTimePriority) {
          result = this.priority[0].id;
        } else if (this.hasMultipleTimePriorities) {
          result = this.selected || false;
        } else {
          result = true;
        }
      }

      this.resolve(result);
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal {
    .modal-dialog {
      .modal-footer {
        justify-content: space-between;
      }
    }
  }
}
</style>

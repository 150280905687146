<template>
  <Progress
    v-bind:variant="progressColor"
    v-bind:value="progressValue"
    v-bind:max="progressMax"
  >
    <template v-slot:left>
      <span class="text-info">Prioritate timp de:</span>

      <span
        class="text-dark"
        v-for="(priority, index) in entries"
        v-bind:key="priority.id"
        v-b-tooltip="`Expiră la ${priority.expires.format('HH:mm:ss')}`"
      >
        <template v-if="index != entries.length - 1">
          {{ priority.duration | duration }},
        </template>

        <template v-else>
          <!-- Last element -->
          {{ priority.duration | duration }}
        </template>
      </span>
      |
      <span class="text-danger" v-if="expired">Expirată</span>
      <span class="text-warning" v-else-if="almostExpired">Expiră: {{ remaining | future }}</span>
      <span class="text-success" v-else>Expiră: {{ remaining | future }}</span>
    </template>

    <template v-slot:right>
      <b-link class="text-muted text-hover-danger" v-on:click="revoke">
        anulează
      </b-link>
    </template>
  </Progress>
</template>

<script>
import lodash from "lodash";
import moment from "moment";

import Progress from "@/view/content/widgets/Progress.vue";



function mapPriorityEntry(priority) {
  const granted  = moment(priority.granted);
  const expires  = moment(priority.expires);
  const duration = moment.duration(expires.diff(granted));
  const elapsed  = moment.duration(moment().diff(granted));

  let remaining = moment.duration(expires.diff(moment()));
  if (remaining.asMilliseconds() < 0)
    remaining = moment.duration(0);

  return {
    id: priority.id,

    granted,
    expires,
    duration,
    elapsed,
    remaining,
  };
}



export default {
  components: {
    Progress,
  },

  filters: {
    future:   duration => moment.duration(duration).humanize(true),
    duration: duration => moment.duration(duration).humanize(),
  },

  props: {
    priorities: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    const entries = this.priorities.map(mapPriorityEntry);

    const granted  = lodash.minBy(entries, p => p.granted.valueOf()).granted;
    const expires  = lodash.maxBy(entries, p => p.expires.valueOf()).expires;
    const duration = moment.duration(expires.diff(granted));
    const elapsed  = moment.duration(moment().diff(granted));

    let remaining = moment.duration(expires.diff(moment()));
    if (remaining.asMilliseconds() < 0)
      remaining = moment.duration(0);

    return {
      entries: Object.freeze(entries),

      granted:   Object.freeze(granted),
      expires:   Object.freeze(expires),
      duration:  Object.freeze(duration),
      elapsed:   Object.freeze(elapsed),
      remaining: Object.freeze(remaining),

      progressValue: elapsed.asSeconds(),
      progressMax:   duration.asSeconds(),
    };
  },

  computed: {
    expired() {
      return this.remaining.asMinutes() <= 0;
    },

    almostExpired() {
      return this.remaining.asMinutes() < 10;
    },

    progressColor() {
      if (this.remaining.asMinutes() <= 0) {
        return "danger";
      } else if (this.remaining.asMinutes() < 10) {
        return "warning";
      } else {
        return "primary";
      }
    },
  },

  methods: {
    revoke() {
      this.$emit("revoke", this.priorities);
    },
  },
};
</script>

<template>
  <div v-if="range || time.length > 0">
    <CarPriorityRange
      class="mb-2"
      v-if="range"
      v-bind:priority="range"
      v-on="$listeners"
    />

    <CarPriorityTime
      v-if="time.length > 0"
      v-bind:priorities="time"
      v-on="$listeners"
    />
  </div>

  <div v-else>
    <span class="text-muted font-weight-bolder d-block font-size-lg">
      Nu are prioritate.
    </span>
  </div>
</template>

<script>
import CarPriorityRange from "./CarPriorityRange.vue";
import CarPriorityTime  from "./CarPriorityTime.vue";

export default {
  components: {
    CarPriorityRange,
    CarPriorityTime,
  },

  props: {
    range: {
      type: Object,
      default: null,
    },

    time: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {};
  },
};
</script>

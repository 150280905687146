<template>
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div class="text-muted font-size-sm font-weight-bold">
        <slot name="left" />
      </div>

      <div class="text-muted font-size-sm font-weight-bold">
        <slot name="right" />
      </div>
    </div>

    <b-progress
      v-bind:class="[ `progress-${size}` ]"
      v-bind:value="value"
      v-bind:max="max"
      v-bind:variant="variant"
    />

    <div class="d-flex flex-column">
      <div class="d-flex align-items-center justify-content-between mb-1">
        <div class="text-muted font-size-sm font-weight-bold">
          <slot name="bottom-left" />
        </div>

        <div class="text-muted font-size-sm font-weight-bold">
          <slot name="bottom-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const sizes = [ "xs", "sm", "md", "lg", "xl" ];

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },

    max: {
      type: Number,
      default: 100,
    },

    size: {
      type: String,
      default: "xs",
      validator: (size) => sizes.includes(size),
    },

    variant: {
      type: String,
      default: "primary",
    },
  },

  data() {
    return {};
  },
};
</script>

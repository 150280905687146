<template>
  <div class="car-suspension">
    <div
      class="driver-suspension text-muted"
      v-for="driver in suspended"
      v-bind:key="driver.id"
    >
      <span class="text-info font-weight-bold">
        {{ driver.name }}
      </span>

      <span class="text-dark">
        suspendat pentru
      </span>

      <span class="text-danger font-weight-bold" v-b-tooltip v-bind:title="driver.suspended.until | timestamp">
        {{ driver.suspended.until | future }}
      </span>

      <template v-if="driver.suspended.reason">
        |
        <span class="text-info font-size-sm font-weight-bold">
          Motiv: {{ driver.suspended.reason }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  filters: {
    future:    timestamp => moment(timestamp).fromNow(true),
    timestamp: timestamp => moment(timestamp).format('D MMMM Y [la] HH:mm:ss'),
  },

  props: {
    drivers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return { };
  },

  computed: {
    suspended() {
      return this.drivers.filter(driver => driver.suspended);
    },
  },
};
</script>

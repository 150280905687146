<template>
  <b-modal
    centered
    ok-title="Acordă prioritate"
    cancel-title="Renunță"
    cancel-variant="danger"
    v-model="visible"
    v-bind:title="title"
    v-bind:ok-disabled="!selected"
    v-on:hide="resolve($event.trigger === 'ok' ? selected : false)"
  >
    <b-alert
      v-if="allowRange"
      show
      variant="primary"
      class="alert-custom alert-outline-primary alert-outline-2x"
    >
      <b-form-group
        label="Prioritate în zonă:"
        label-class="h4 text-primary"
      >
        <b-form-radio-group
          buttons
          button-variant="outline-primary"
          v-model="selected"
          v-bind:options="rangeOptions"
        />
      </b-form-group>
    </b-alert>

    <b-alert
      show
      variant="primary"
      class="alert-custom alert-outline-info alert-outline-2x"
    >
      <b-form-group
        label="Prioritate schimb de tură / POS / comandă:"
        label-class="h4 text-info"
      >
        <b-form-radio-group
          buttons
          button-variant="outline-info"
          v-model="selected"
          v-bind:options="timeOptions"
        />
      </b-form-group>
    </b-alert>
  </b-modal>
</template>

<script>
import moment from "moment";

const rangeDuration = moment.duration(2, "hours");
const rangeOptions  = [ 1.5, 3 ];
const timeOptions   = [ 8 ];

function mapRangeOption(radius) {
  return {
    text: `${radius} km`,
    value: { radius, duration: rangeDuration },
  }
}

function mapTimeOption(hours) {
  const duration = moment.duration(hours, "hours");

  return {
    text:  duration.humanize(),
    value: { duration },
  }
}

export default {
  data() {
    return {
      allowRange:   true,
      rangeOptions: rangeOptions.map(mapRangeOption),
      timeOptions:  timeOptions.map(mapTimeOption),

      car: null,

      selected: null,

      resolve: null,
      reject:  null,

      visible: false,
    };
  },

  computed: {
    title() {
      if (this.car) {
          return `Acordă prioritate - ind. ${this.car.id} șofer ${this.car.driver.name}`;
      } else {
          return "";
      }
    },
  },

  destroyed() {
    if (this.reject) {
      this.reject(new Error("Dialog destroyed before resolve."));
    }
  },

  methods: {
    show(car, excludeRange) {
      this.car = car;

      this.allowRange = !excludeRange;

      this.selected = null;

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },

    hide() {
      this.car = null;

      this.allowRange = true;;

      this.selected = null;

      this.resolve = null;
      this.reject  = null;

      this.visible = false;
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal {
    .modal-dialog {
      .modal-body {
        .alert {
          &:last-child {
            margin-bottom: 0;
          }

          .form-group {
            margin-bottom: 0.5rem;
          }
        }
      }
      
      .modal-footer {
        justify-content: space-between;
      }
    }
  }
}
</style>
